import React from 'react'
import './App.css'
import AreaChartBiz from './component/AreaChartBiz'
import LineChartBiz from './component/LineChartBiz'
import BarChartBiz from './component/BarChartBiz'
import ComposedChartBiz from './component/ComposedChartBiz'
import ScatterChartBiz from './component/ScatterChartBiz'
import PieChartBiz from './component/PieChartBiz'
import RadarChartBiz from './component/RadarChartBiz'
import Navbar from './component/Navbar'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';





const App=()=>{
  return(
    <div>
      
      {/* <LineCharts/> */}
      <Router>
        <Navbar/>
        <Switch>
          <Route path='/' exact component={LineChartBiz}/>
          <Route path='/AreaChart' component={AreaChartBiz}/>
          <Route path='/BarChart' component={BarChartBiz}/>
          <Route path='/ComposedChart' component={ComposedChartBiz}/>
          <Route path='/ScatterChart' component={ScatterChartBiz}/>
          <Route path='/PieChart' component={PieChartBiz}/>
          <Route path='/RadarChart' component={RadarChartBiz}/>
          
        </Switch>
      </Router>
      
      

    </div>
  )
}
export default App