import React, { useState } from 'react'
import * as FaIcons from 'react-icons/fa'
//import * as AiIcons from 'react-icons/ai'
import { Link } from 'react-router-dom'
import {SideBarData} from './SideBarData'


function Navbar() {
    const [sidebar,setSidebar]=useState(false)
    const showSideBar = ()=>{
        setSidebar(!sidebar)    
    }
    return (
        <div className="navbar">
            <Link className="menu-bars">
            <FaIcons.FaBars onClick={showSideBar}/>
            </Link>
            <nav className='nav-menu active'>
                <ul className="nav-menu-items" >
                    {/* <li className="navbar-toggle">
                        <Link to="#" className="menu-bars" onClick={showSideBar} >
                            <AiIcons.AiOutlineClose/>
                        </Link>
                    </li> */}
                    {SideBarData.map((item,index) =>{
                        return(
                            <li key={index} className={item.cName}>
                                <Link to={item.path}>
                                    <span>{item.title}</span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </nav>
            
        </div>
    )
}

export default Navbar
