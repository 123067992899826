
export const SideBarData=[
    
    {
        title:'LineChart',
        path:'/',
        cName:'nav-text'
    },
    {
        title:'AreaChart',
        path:'/AreaChart',
        cName:'nav-text'
    },
    {
        title:'BarChart',
        path:'/BarChart',
        cName:'nav-text'
    },
    {
        title:'ComposedChart',
        path:'/ComposedChart',
        cName:'nav-text'
    },
    {
        title:'ScatterChart',
        path:'/ScatterChart',
        cName:'nav-text'
    },
    {
        title:'PieChart',
        path:'/PieChart',
        cName:'nav-text'
    },
    {
        title:'RadarChart',
        path:'/RadarChart',
        cName:'nav-text'
    },
]