import React, { useState } from 'react'
import {Chart,Line,Area,Interval, Interaction, Legend,Axis} from 'bizcharts'
import DataSet from '@antv/data-set'
import {SketchPicker} from 'react-color'
import {Slider} from '@material-ui/core'


const data = [
    {
      "name": "Page A",
      "uv": 4000,
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "Page B",
      "uv": 3000,
      "pv": 1398,
      "amt": 2210
    },
    {
      "name": "Page C",
      "uv": 2000,
      "pv": 9800,
      "amt": 2290
    },
    {
      "name": "Page D",
      "uv": 2780,
      "pv": 3908,
      "amt": 2000
    },
    {
      "name": "Page E",
      "uv": 1890,
      "pv": 4800,
      "amt": 2181
    },
    {
      "name": "Page F",
      "uv": 2390,
      "pv": 3800,
      "amt": 2500
    },
    {
      "name": "Page G",
      "uv": 3490,
      "pv": 4300,
      "amt": 2100
    }
  ]
  
  const dv = new DataSet.DataView().source(data);
  dv.transform({
      type:'fold',
      fields:['uv','pv','amt'],
      key:'type',
      value:'value',
  });
  
  const AreaChartBiz = () =>{
    const initialcolor  = {
      colorpv:"#7ED321",
      coloruv:"#F8E71C",
    }
    const initialShape={
      shape:"",
      size:3,
      width:730,
      height:250,
      label:12000,
    }
    // const handleChangeCompleteUv = (c) => {
      
    //    setChangeColor({...changeColor,coloruv: c.hex} )
      
    // };
    const handleChangeCompletePv = (c) => {
      
      setChangeColor({...changeColor,colorpv: c.hex} )
      
    };
    const handleWidthchange=(event,newValue)=>{
      setshapeofGraph({...shapeofGraph,width:newValue})
    }
    const handleHeightchange=(event,newValue)=>{
      setshapeofGraph({...shapeofGraph,height:newValue})
    }
    const handleSizechange=(event,newValue)=>{
      setshapeofGraph({...shapeofGraph,size:newValue})
    }
    const handleLabelchange=(event,newValue)=>{
      setshapeofGraph({...shapeofGraph,label:newValue})
    }
    const handleTooltipStatus=()=>{
      setTooltipStatus(!tooltipStatus)
    }
    const handlLabelStatus=()=>{
        setLabelStatus(!labelStatus)
      }
    
    const [changeColor,setChangeColor]=useState(initialcolor)
    const [shapeofGraph,setshapeofGraph]=useState(initialShape)
    const [tooltipStatus,setTooltipStatus]= useState(true)
    const [labelStatus,setLabelStatus]=useState(true)
    const scale = {
      pv:{
        min: 0,
        max:shapeofGraph.label,
        
      },
      uv:{
        min: 0,
        max:shapeofGraph.label,
        
      },
      amt:{
        min: 0,
        max:shapeofGraph.label,
        
      },
      value: {
        min: 0,
        max:shapeofGraph.label,
       
      },
      name: {
        range: [0.05, 0.95],
      },
    };
      return (
          <div className="chartdiv">
              <h2>ComposedChart (BizChart)</h2>
              <Chart className="chart-content" animate={true} scale={scale} height={shapeofGraph.height} width={shapeofGraph.width} data={data} autoFit >
                <Line tooltip={tooltipStatus?"pv":"false"} 
                size={shapeofGraph.size} color={changeColor.colorpv}
                position="name*pv" label={labelStatus?"pv":""}        
                />
                <Area size={shapeofGraph.size} position="name*uv" label={labelStatus?"uv":""}  tooltip={tooltipStatus?"uv":"false"} />
                <Interval position="name*amt" color="#5E2B8A" size="20" label={labelStatus?"amt":""} tooltip={tooltipStatus?"amt":"false"}  />
                <Axis name="uv" label=""/>
                <Axis name="amt" label=""/>
                <Legend/>
                
                
                <Interaction type="element-highlight" />
                
              </Chart>
              <div className="tablediv">
              <table >
                <tr >
                  <th className="tdleft">Properties</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td className="tdleft">width</td>
                  <td >To set the width of the graph
                    <br/>
                    <div className="slider">
                    Example: {'<Chart width={730}/>'}
                    <Slider value={shapeofGraph.width} 
                    min={500} 
                    max={750} 
                    valueLabelDisplay="auto"
                    onChange={handleWidthchange}/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="tdleft">height</td>
                  <td>To set the height of the graph
                    <br/>
                    <div className="slider">
                    Example: {'<Chart height={250}/>'}
                    <Slider value={shapeofGraph.height} 
                    min={200} 
                    max={350} 
                    valueLabelDisplay="auto"
                    onChange={handleHeightchange}/>
                    </div>
                    </td>
                    
                </tr>
                <tr>
                  <td className="tdleft">
                  color
                  </td>
                  <td>
                    Change the color of the graph<br/>
                    Example: Change color of pv 
                  <SketchPicker color={changeColor.colorpv} onChangeComplete={ handleChangeCompletePv }/>                  
                    {/* <b>Color uv</b>
                    <SketchPicker color={changeColor.coloruv} onChangeComplete={ handleChangeCompleteUv }/> */}
                   
                    </td>
                </tr>
                <tr>
                  <td className="tdleft">position</td>
                  <td>
                    Set the x-axis and y-axis<br/>
                    Example: position="x-axis*y-axis"
                  </td>
                </tr>
                <tr>
                  <td className="tdleft">label</td>
                  <td>
                    Set the label of each point<br/>
                    Example: label="value" 
                    <button onClick={handlLabelStatus}>On/Off</button>
                  </td>
                </tr>
                <tr>
                  <td className="tdleft">adjust</td>
                  <td>
                    Set the data adjustment method of the graph (including 'stack','dodge','jitter','symmertric')
                    <br/>Example: adjust= {'{'}[{'{'}type:'stack',marginRatio:0{'}'}]{'}'}
                  </td>
                </tr>
                <tr>
                  <td className="tdleft">size</td>
                  <td>Set the width size of the pixel on the graph<br/>
                  <div className="slider">
                    Example: {'<Line size={3}/>'}<br/>(Set the size for line)
                    <Slider value={shapeofGraph.size} 
                    min={2} 
                    max={10} 
                    valueLabelDisplay="auto"
                    onChange={handleSizechange}/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="tdleft">tooltip</td>
                  <td>
                    To switch on/off the tooltip when pointing on the graph<br/>
                    Example: To turn on tooltip {'<Line tooltip="type*value"/>'}
                    <button onClick={handleTooltipStatus}>On/Off</button>
                  </td>
                </tr>
                <tr>
                  <td className="tdleft">style</td>
                  <td>
                    To set the design style of the graph<br/>
                    Example: To set the opacity of the graph {'<Line style={{opacity:0.5}}/>'}
                  </td>
                </tr>
                <tr>
                  <td className="tdleft">animate</td>
                  <td>
                    To set the animation of the graph plotting<br/>
                    Example: To set the animation of the graph when plotting {'<Line animate={{animation:"fadeIn"}/>'}
                  </td>
                </tr>
                <tr>
                  <td className="tdleft">scale</td>
                  <td>To set scale of the graph<br/>
                    <div className="slider">
                    Example: Set the maximum scale for the y-axis<br/>{'<Chart scale={{y-axis:{max:12000}}}/>'}
                    <Slider value={shapeofGraph.label} 
                    min={5000} 
                    max={20000} 
                    valueLabelDisplay="auto"
                    onChange={handleLabelchange}/>
                    </div>
                  </td>
                </tr>


          
                </table>
                </div>
          </div>
      )
  }
  export default AreaChartBiz