import React, { useState } from 'react'
import {ScatterChart} from 'bizcharts'
import DataSet from '@antv/data-set'
import {SketchPicker} from 'react-color'
import {Slider} from '@material-ui/core'


const data = [
    {
      "name": "Page A",
      "uv": 4000,
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "Page B",
      "uv": 3000,
      "pv": 1398,
      "amt": 2210
    },
    {
      "name": "Page C",
      "uv": 2000,
      "pv": 9800,
      "amt": 2290
    },
    {
      "name": "Page D",
      "uv": 2780,
      "pv": 3908,
      "amt": 2000
    },
    {
      "name": "Page E",
      "uv": 1890,
      "pv": 4800,
      "amt": 2181
    },
    {
      "name": "Page F",
      "uv": 2390,
      "pv": 3800,
      "amt": 2500
    },
    {
      "name": "Page G",
      "uv": 3490,
      "pv": 4300,
      "amt": 2100
    }
  ]
  
  const dv = new DataSet.DataView().source(data);
  dv.transform({
      type:'fold',
      fields:['uv','pv'],
      key:'type',
      value:'value',
  });
  
  const AreaChartBiz = () =>{
    const initialcolor  = {
      colorpv:"#9013FE",
      coloruv:"#F8E71C",
    }
    const initialShape={
      shape:"line",
      size:3,
      width:730,
      height:250,
    }
    // const handleChangeCompleteUv = (c) => {
      
    //    setChangeColor({...changeColor,coloruv: c.hex} )
      
    // };
    const handleChangeCompletePv = (c) => {
      
      setChangeColor({...changeColor,colorpv: c.hex} )
      
    };
    
    const handleWidthchange=(event,newValue)=>{
      setshapeofGraph({...shapeofGraph,width:newValue})
    }
    const handleHeightchange=(event,newValue)=>{
      setshapeofGraph({...shapeofGraph,height:newValue})
    }
    const handleSizechange=(event,newValue)=>{
      setshapeofGraph({...shapeofGraph,size:newValue})
    }
    const handleTooltipStatus=()=>{
      setTooltipStatus(!tooltipStatus)
    }
    const handlLabelStatus=()=>{
        setLabelStatus(!labelStatus)
      }
    const [changeColor,setChangeColor]=useState(initialcolor)
    const [shapeofGraph,setshapeofGraph]=useState(initialShape)
    const [tooltipStatus,setTooltipStatus]= useState(true)
    const [labelStatus,setLabelStatus]=useState(true)
      return (
          <div className="chartdiv">
              <h2>ScatterChart (BizChart)</h2>
              <ScatterChart className="chart-content" height={shapeofGraph.height} width={shapeofGraph.width} data={dv.rows} autoFit
               
                tooltip={{visible:tooltipStatus}}
                pointSize={shapeofGraph.size}
                xField='amt'    
                yField='value'
                colorField="type"
                color={[changeColor.colorpv,changeColor.coloruv]}
                label={labelStatus?"pv":""}
                // trendline={{
                //     type:'poly',
                //     style:{
                //         stroke:'black',
                //         lineWidth:1,
                //     },
                //     showConfidence:true,
                //     confidenceStyle:{
                //         fill:'red',
                //         opacity:0.5
                //     }
                // }}
               
              />
              <div className="tablediv">
              <table >
                <tr >
                  <th className="tdleft">Properties</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td className="tdleft">width</td>
                  <td >To set the width of the graph
                    <br/>
                    <div className="slider">
                    Example: {'<ScatterChart width={730}/>'}
                    <Slider value={shapeofGraph.width} 
                    min={500} 
                    max={750} 
                    valueLabelDisplay="auto"
                    onChange={handleWidthchange}/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="tdleft">height</td>
                  <td>To set the height of the graph
                    <br/>
                    <div className="slider">
                    Example: {'<ScatterChart height={250}/>'}
                    <Slider value={shapeofGraph.height} 
                    min={200} 
                    max={350} 
                    valueLabelDisplay="auto"
                    onChange={handleHeightchange}/>
                    </div>
                    </td>
                    
                </tr>
                <tr>
                  <td className="tdleft">
                  color
                  </td>
                  <td>
                    Change the color of the graph<br/>
                    Example: Change color of pv 
                  <SketchPicker color={changeColor.colorpv} onChangeComplete={ handleChangeCompletePv }/>                  
                    {/* <b>Color uv</b>
                    <SketchPicker color={changeColor.coloruv} onChangeComplete={ handleChangeCompleteUv }/> */}
                   
                    </td>
                </tr>
                <tr>
                  <td className="tdleft">xField</td>
                  <td>
                    Set the x-axis<br/>
                    Example: xField="pv"
                  </td>
                </tr>
                <tr>
                  <td className="tdleft">yField</td>
                  <td>
                    Set the y-axis<br/>
                    Example: yField="uv"
                  </td>
                </tr>
                <tr>
                  <td className="tdleft">label</td>
                  <td>
                    Set the label of each point<br/>
                    Example: label="value" 
                    <button onClick={handlLabelStatus}>On/Off</button>
                  </td>
                </tr>
                <tr>
                  <td className="tdleft">pointSize</td>
                  <td>Set the size of the pixel on the graph<br/>
                  <div className="slider">
                    Example: {'<ScatterChart pointSize={3}/>'}<br/>
                    <Slider value={shapeofGraph.size} 
                    min={2} 
                    max={10} 
                    valueLabelDisplay="auto"
                    onChange={handleSizechange}/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="tdleft">tooltip</td>
                  <td>
                    To switch on/off the tooltip when pointing on the graph<br/>
                    Example: To turn on tooltip {'<ScatterChart tooltip={{visible:true}}/>'}
                    <button onClick={handleTooltipStatus}>On/Off</button>
                  </td>
                </tr>
                <tr>
                  <td className="tdleft">pointStyle</td>
                  <td>
                    To set the design style of the graph<br/>
                    Example: To set the opacity of the graph {'<ScatterChart style={{opacity:0.5}}/>'}
                  </td>
                </tr>
                </table>
                </div>
          </div>
      )
  }
  export default AreaChartBiz